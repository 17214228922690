import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RegisterLiveInst } from "../../Network/InstApi";
import { BuildingAdd } from "react-bootstrap-icons";
import { CountryCities } from "../../Utils/CountryCities";

const RegisterInst = () => {
  useEffect(() => {
    if (Intl) {
      let TzArr = Intl.DateTimeFormat().resolvedOptions().timeZone.split("/");
      let userCity = TzArr[TzArr.length - 1];
      let userCountry = CountryCities[userCity];
      setCountry(userCountry);
    }
  }, []);

  const [ShowModal, setShowModal] = useState(false);
  const [Name, setName] = useState("");
  const User = useSelector((state) => state.auth.user);
  const [Loading, setLoading] = useState(false);
  const [Country, setCountry] = useState("");

  //redux dispatch
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const HandleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let clientId = `${parseInt(Date.now() / 1000)}`;
    let linker = `${Date.now()}`;

    await RegisterLiveInst(
      {
        name: Name,
        linker,
        motto: "",
        email: User.email,
        trace: linker,
        deleted: 0,
        sub: Country,
        live: 1,
        clientId,
        userLinker: User.linker,
      },
      User,
      dispatch
    );
    setLoading(false);
    setShowModal(false);
    navigate(`/${Name}`);
    setName("");
  };

  return (
    <div className="col-6 col-md-4 col-lg-3">
      <div className="dashboard py-2" onClick={() => setShowModal(true)}>
        <div className="d-flex justify-content-center">
          <BuildingAdd className="dash-icon"></BuildingAdd>
        </div>

        <p className="text-center dash-text">Register New Institution</p>
      </div>

      <Modal
        show={ShowModal}
        onHide={() => setShowModal(false)}
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Register New Institution</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={HandleSubmit}>
            <div className="form-group">
              <label className="mb-2">Institution Name:</label>
              <input
                className="rounded form-control"
                placeholder="Institution Name"
                value={Name}
                onChange={(e) => setName(e.target.value)}
                required
              />
              <hr />
            </div>
            <div className="d-flex justify-content-around mb-2">
              {Loading ? (
                <span className="spinner-border text-primary"></span>
              ) : (
                <Button variant="primary" type="submit">
                  Submit
                </Button>
              )}
              <Button
                variant="secondary"
                type="button"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default RegisterInst;
