import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  addLiveAppointment,
  updateLiveAppointment,
} from "../../Network/AppointmentApi";

const AppointmentCrud = (props) => {
  //redux dispatch
  const dispatch = useDispatch();

  const User = useSelector((state) => state.auth.user);
  const ActiveCred = useSelector((state) => state.cred.activeCred);
  const [Loading, setLoading] = useState(false);

  //edit appointment
  const EditAppointment = async () => {
    let { credLinker, trace, deleted } = props.appointment;

    trace = Date.now();
    credLinker = ActiveCred.linker;
    deleted = props.type === "delete" ? 1 : deleted;

    await updateLiveAppointment(
      { ...props.appointment, trace, credLinker, deleted },
      User.token,
      dispatch
    );
  };

  const SaveAppointment = async () => {
    let trace = Date.now();

    await addLiveAppointment(
      {
        ...props.appointment,
        instLinker: ActiveCred.linker,
        credLinker: ActiveCred.linker,
        live: 1,
        status: "current",
        trace,
        deleted: 0,
      },
      ActiveCred.instLinker,
      User.token,
      dispatch
    );
  };

  const HandleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (props.type === "add") {
      await SaveAppointment();
    } else {
      await EditAppointment();
    }
    setLoading(false);
    props.setShowModal(false);
  };

  return (
    <Modal
      show={props.ShowModal}
      onHide={() => props.setShowModal(false)}
      backdrop="static"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <p className="text-capitalize text-center">
            {props.type} Appointment
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={HandleSubmit}>
          <div className="form-group">
            <label className="mb-2 text-capitalize"> Patient Name:</label>
            <input
              className="rounded form-control"
              placeholder={`enter patient name`}
              value={props.appointment.name}
              onChange={(e) =>
                props.setAppointment({
                  ...props.appointment,
                  name: e.target.value,
                })
              }
              required
              readOnly={props.type === "delete" ? true : false}
            />
            <hr />
          </div>
          <div className="form-group">
            <label className="mb-2 text-capitalize"> Patient Contact:</label>
            <input
              className="rounded form-control"
              placeholder={`enter patient contact`}
              value={props.appointment.contact}
              onChange={(e) =>
                props.setAppointment({
                  ...props.appointment,
                  contact: e.target.value,
                })
              }
              readOnly={props.type === "delete" ? true : false}
            />
            <hr />
          </div>
          <div className="form-group">
            <label className="mb-2 text-capitalize"> Patient Email:</label>
            <input
              className="rounded form-control"
              placeholder={`enter patient email`}
              value={props.appointment.email}
              onChange={(e) =>
                props.setAppointment({
                  ...props.appointment,
                  email: e.target.value,
                })
              }
              readOnly={props.type === "delete" ? true : false}
              type={"email"}
            />
            <hr />
          </div>
          {props.type === "edit" ? (
            <div className="form-group">
              <label className="mb-2 text-capitalize">
                {" "}
                Appointment Status:
              </label>
              <select
                className="form-control rounded"
                value={props.appointment.status}
                onChange={(e) =>
                  props.setAppointment({
                    ...props.appointment,
                    status: e.target.value,
                  })
                }
              >
                <option value={""}>Select Status</option>
                <option value={"current"}>Current</option>
                <option value={"attended"}>Attended</option>
                <option value={"skipped"}>Skipped</option>
                <option value={"cancelled"}>Cancelled</option>
              </select>
              <hr />
            </div>
          ) : null}
          <div className="form-group">
            <label className="mb-2 text-capitalize">
              {" "}
              Appointment DateTime:
            </label>
            <input
              className="rounded form-control"
              placeholder={`select appointment date and time`}
              value={props.appointment.date}
              onChange={(e) =>
                props.setAppointment({
                  ...props.appointment,
                  date: e.target.value,
                })
              }
              readOnly={props.type === "delete" ? true : false}
              required
              type={"datetime-local"}
            />
            <hr />
          </div>
          {props.Services.length > 0 ? (
            <div className="form-group">
              <label className="mb-2 text-capitalize">
                Appointment Service
              </label>
              <select
                className="rounded form-control"
                value={props.appointment.serviceLinker}
                onChange={(e) =>
                  props.setAppointment({
                    ...props.appointment,
                    serviceLinker: e.target.value,
                  })
                }
                required
                readOnly={props.type === "delete" ? true : false}
              >
                <option value={""}>Select Service</option>
                {props.Services.map((service, index) => (
                  <option key={index} value={service.linker}>
                    {service.name}
                  </option>
                ))}
              </select>
              <hr />
            </div>
          ) : null}
          <div className="form-group">
            <label className="mb-2 text-capitalize">Appointment Details:</label>
            <textarea
              className="rounded form-control"
              placeholder={`enter appointment details`}
              value={props.appointment.details}
              onChange={(e) =>
                props.setAppointment({
                  ...props.appointment,
                  details: e.target.value,
                })
              }
              required
              readOnly={props.type === "delete" ? true : false}
            />
            <hr />
          </div>
          <div className="d-flex justify-content-around mb-2">
            {Loading ? (
              <span className="spinner-border text-primary"></span>
            ) : props.type === "delete" ? (
              <Button variant="danger" type="submit">
                Delete
              </Button>
            ) : (
              <Button variant="primary" type="submit">
                Save
              </Button>
            )}
            <Button
              variant="secondary"
              type="button"
              onClick={() => props.setShowModal(false)}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AppointmentCrud;
