import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  addLiveStoreEntry,
  updateLiveStoreEntry,
} from "../../../Network/StoreEntryApi";

const ItemStoreEntry = (props) => {
  //redux dispatch
  const dispatch = useDispatch();

  const User = useSelector((state) => state.auth.user);
  const online = useSelector((state) => state.online.online);
  const ActiveCred = useSelector((state) => state.cred.activeCred);
  const [Loading, setLoading] = useState(false);

  //edit storeEntry
  const EditStoreEntry = async () => {
    let { credLinker, trace, live, deleted } = props.storeEntry;

    trace = Date.now();
    credLinker = ActiveCred.linker;
    deleted = props.type === "delete" ? 1 : deleted;

    await updateLiveStoreEntry(
      { ...props.storeEntry, trace, credLinker, deleted },
      User.token,
      dispatch
    );
  };

  const SaveStoreEntry = async () => {
    let trace = Date.now();

    await addLiveStoreEntry(
      {
        ...props.storeEntry,
        instLinker: ActiveCred.linker,
        credLinker: ActiveCred.linker,
        live: 1,
        status: 0,
        trace,
        deleted: 0,
      },
      ActiveCred.instLinker,
      User.token,
      dispatch
    );
  };

  const HandleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (props.type === "add" || props.type === "subtract") {
      await SaveStoreEntry();
    } else {
      await EditStoreEntry();
    }
    setLoading(false);

    props.setShowModal(false);
  };

  return (
    <Modal
      show={props.ShowModal}
      onHide={() => props.setShowModal(false)}
      backdrop="static"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <p className="text-capitalize text-center">
            {props.type} {props.item.name} StoreEntry
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={HandleSubmit}>
          <div className="form-group">
            <label className="mb-2 text-capitalize">StoreEntry Type:</label>
            <select
              className="rounded form-control"
              value={props.storeEntry.type}
              onChange={(e) =>
                props.setStoreEntry({
                  ...props.storeEntry,
                  type: e.target.value,
                })
              }
              required
              readOnly={props.type === "delete" ? true : false}
            >
              <option value={""}>Select StoreEntry Type</option>
              {props.type === "add" ||
              props.type === "edit" ||
              props.type === "delete" ? (
                <>
                  <option value={"received"}>Received</option>
                  <option value={"return-issued"}>Return Issued</option>
                </>
              ) : null}
              {props.type === "subtract" ||
              props.type === "edit" ||
              props.type === "delete" ? (
                <>
                  <option value={"issued"}>Issued</option>
                  <option value={"return-received"}>Return Received</option>
                  <option value={"damaged"}>Damaged</option>
                </>
              ) : null}
            </select>
            <hr />
          </div>
          {props.storeEntry.type !== "" ? (
            <div className="form-group">
              <label className="mb-2 text-capitalize">
                {" "}
                {props.storeEntry.type === "issued" ||
                props.storeEntry.type === "return-issued"
                  ? "Receiver"
                  : "Supplier"}{" "}
                Name:
              </label>
              <input
                className="rounded form-control"
                placeholder={`Enter ${
                  props.storeEntry.type === "issued" ||
                  props.storeEntry.type === "return-issued"
                    ? "Receiver"
                    : "Supplier"
                } Name`}
                value={props.storeEntry.supplier}
                onChange={(e) =>
                  props.setStoreEntry({
                    ...props.storeEntry,
                    supplier: e.target.value,
                  })
                }
                required
                readOnly={props.type === "delete" ? true : false}
              />
              <hr />
            </div>
          ) : null}

          <div className="form-group">
            <label className="mb-2 text-capitalize"> Quantity:</label>
            <input
              className="rounded form-control"
              placeholder={`enter quantity`}
              value={props.storeEntry.quantity}
              onChange={(e) =>
                props.setStoreEntry({
                  ...props.storeEntry,
                  quantity: e.target.value,
                })
              }
              required
              readOnly={props.type === "delete" ? true : false}
              type="number"
            />
            <hr />
          </div>
          <div className="form-group">
            <label className="mb-2 text-capitalize">StoreEntry Details:</label>
            <textarea
              className="rounded form-control"
              placeholder={`enter storeEntry details`}
              value={props.storeEntry.details}
              onChange={(e) =>
                props.setStoreEntry({
                  ...props.storeEntry,
                  details: e.target.value,
                })
              }
              required
              readOnly={props.type === "delete" ? true : false}
            />
            <hr />
          </div>
          <div className="d-flex justify-content-around mb-2">
            {Loading ? (
              <span className="spinner-border text-primary"></span>
            ) : props.type === "delete" ? (
              <Button variant="danger" type="submit">
                Delete
              </Button>
            ) : (
              <Button variant="primary" type="submit">
                Save
              </Button>
            )}
            <Button
              variant="secondary"
              type="button"
              onClick={() => props.setShowModal(false)}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ItemStoreEntry;
