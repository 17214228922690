import React from "react";
import image from "../../assets/images/health-management-tech-system.png";
import patient from "../../assets/images/health-management-tech-system-patient.jpg";
import staff from "../../assets/images/health-management-tech-system-staff.jpg";
import ward from "../../assets/images/health-management-tech-system-wards.jpg";
import bed from "../../assets/images/health-management-tech-system-beds.jpg";
import bill from "../../assets/images/health-management-tech-system-bill.jpg";
import health from "../../assets/images/health-management-tech-system-health.jpg";
import inventory from "../../assets/images/health-management-tech-system-inventory.jpg";
import expenses from "../../assets/images/health-management-tech-system-expenses.jpg";
import insurance from "../../assets/images/health-management-tech-system-insurance.jpg";
import appointment from "../../assets/images/health-management-tech-system-appointments.jpg";
import service from "../../assets/images/health-management-tech-system-services.jpg";
import pharmacy from "../../assets/images/health-management-tech-system-pharmacy.jpg";
import AuthModal from "../Auth/AuthModal";
import Header from "../../components/Header";
import PwaInstall from "../../Utils/PwaInstall";

const Home = () => {
	return (
		<div
			style={{
				minHeight: "100vh",
				minWidth: "100vw",

				backgroundImage: `url(${image})`,
				backgroundSize: "cover",
				backgroundRepeat: "repeat-y",
				backgroundPosition: "center",
			}}
		>
			<Header></Header>
			<div
				style={{
					marginTop: "40px",
					backgroundColor: "rgba(64, 99, 112, 0.5)",
					minHeight: "100vh",
					minWidth: "100vw",
				}}
			>
				<div style={{ minHeight: "10vh" }}>
					<p className="text-light text-center h5 bg-dark">
						Become A World Class Manager And Administrator
					</p>
				</div>

				<div
					style={{
						backgroundColor: "rgba(255, 255, 255)",
						borderRadius: "20%",
						padding: "2vh",
					}}
				>
					{" "}
					<p className="text-center h6">
						Eliminate Stress Of Tedious Sheets And Paper Work
					</p>{" "}
					<AuthModal></AuthModal>
					<div className="d-flex justify-content-around mt-1">
						{" "}
						<a
							download
							href="https://dl.dropbox.com/scl/fi/ulfgzcgrvbm4upjghox6v/Health-Techsystem-1.0.zip?rlkey=rhwq4gfbl7oczgoa0u4kdeiuv&st=0zwa7ooi&dl=0"
						>
							<button className="btn btn-secondary rounded-pill">
								Download Software (Offline & Online)
							</button>
						</a>
						<PwaInstall title={"(Online Only)"}></PwaInstall>
					</div>
				</div>
				<div className="d-flex justify-content-center">
					<iframe
						width="560"
						height="315"
						src="https://www.youtube.com/embed/cXuDPkJnX6k?si=RXiRproi2IO2F8kP"
						title="Hospital/Clinic Management Software System"
						frameborder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
						allowfullscreen
					></iframe>
				</div>
				<div className="row bg-transparent d-flex justify-content-around m-2">
					<div className="col-md-3 bg-transparent my-1">
						<div className="card rounded">
							<div className="card-header">
								<p className="h5 card-title">Staff Access</p>
							</div>
							<img
								className="card-img-top"
								src={staff}
								alt="health-management-tech-system-staff"
							/>
							<div className="mx-1">
								<p className="card-text">
									Improve Health Facilty connectivity by adding staffs and
									manage their access levels from Admin, Accounts, and Health.
								</p>
								<p className="card-text">
									Staffs Can use the email you register them with to access
									their portal
								</p>
							</div>
						</div>
					</div>
					<div className="col-md-3 bg-transparent my-1">
						<div className="card rounded">
							<div className="card-header">
								<p className="h5 card-title">Patient Records</p>
							</div>
							<img
								className="card-img-top"
								src={patient}
								alt="health-management-tech-system-patient"
							/>
							<div className="mx-1 py-3">
								<p className="card-text"></p>
								<p className="card-text"></p>
								<p className="card-text">
									Manage patient records with ease helps you to add and filter
									patients by wards and be able to view all patients information
									from one place
								</p>
								<p className="card-text"></p>
								<p className="card-text"></p>
							</div>
						</div>
					</div>
					<div className="col-md-3 bg-transparent my-1">
						<div className="card rounded">
							<div className="card-header">
								<p className="h5 card-title">Pharmacy Manager</p>
							</div>
							<img
								className="card-img-top"
								src={pharmacy}
								alt="health-management-tech-system-pharmacy"
							/>
							<div className="mx-1 py-3">
								<p className="card-text">
									Manage Pharmacy with ease with product categories
								</p>
								<p className="card-text">Add products to the pharmacy</p>
								<p className="card-text">Has a pont of sale</p>
								<p className="card-text"></p>
								<p className="card-text pb-1"></p>
							</div>
						</div>
					</div>
					<div className="col-md-3 bg-transparent my-1">
						<div className="card rounded">
							<div className="card-header">
								<p className="h5 card-title">Wards Manager</p>
							</div>
							<img
								className="card-img-top"
								src={ward}
								alt="health-management-tech-system-wards"
							/>
							<p className="card-text"></p>
							<p className="card-text"></p>
							<div className="mx-1 py-4">
								<p className="card-text">
									Enjoy maximum flexibility by adding your facility wards
									helping you to filter patients accordingly
								</p>
								<p className="card-text"></p>
								<p className="card-text pb-2"></p>
							</div>
						</div>
					</div>
					<div className="col-md-3 bg-transparent my-1">
						<div className="card rounded">
							<div className="card-header">
								<p className="h5 card-title">Ward Beds</p>
							</div>
							<img
								className="card-img-top"
								src={bed}
								alt="health-management-tech-system-bed"
							/>
							<div className="mx-1 py-5">
								<p className="card-text"></p>
								<p className="card-text"></p>
								<p className="card-text">
									Add each ward beds and view occupied and empty beds
								</p>
								<p className="card-text"></p>
								<p className="card-text pb-1"></p>
								<p className="card-text pb-3"></p>
							</div>
						</div>
					</div>
					<div className="col-md-3 bg-transparent my-1">
						<div className="card rounded">
							<div className="card-header">
								<p className="h5 card-title">Health Records</p>
							</div>
							<img
								className="card-img-top"
								src={health}
								alt="health-management-tech-system-health"
							/>
							<div className="mx-1 py-5">
								<p className="card-text">
									Add and download each patient health records for easy access
								</p>
								<p className="card-text">
									If online health officers can do this interconnectively
								</p>
							</div>
						</div>
					</div>
					<div className="col-md-3 bg-transparent my-1">
						<div className="card rounded">
							<div className="card-header">
								<p className="h5 card-title">Bill Invoices And Payments</p>
							</div>
							<img
								className="card-img-top"
								src={bill}
								alt="health-management-tech-system-bill"
							/>
							<div className="mx-1">
								<ul>
									<li>Invoice various patients bills</li>

									<li>Bills payment is simple and direct</li>
									<li>Bill Balances which can be filtred by ward with total</li>
									<li>Each patient bill Statement and downloadable as pdf</li>
								</ul>
							</div>
						</div>
					</div>
					<div className="col-md-3 bg-transparent my-1">
						<div className="card rounded">
							<div className="card-header">
								<p className="h5 card-title">Appointments</p>
							</div>
							<img
								className="card-img-top"
								src={appointment}
								alt="health-management-tech-system-appointment"
							/>
							<div className="mx-1 py-5">
								<p className="card-text">Manage your patient appointments</p>
								<p className="card-text">
									You can filter the appointments with service, date and time
								</p>
							</div>
						</div>
					</div>
					<div className="col-md-3 bg-transparent my-1">
						<div className="card rounded">
							<div className="card-header">
								<p className="h5 card-title">Accepted Insurance</p>
							</div>
							<img
								className="card-img-top"
								src={insurance}
								alt="health-management-tech-system-insurance"
							/>
							<div className="mx-1 py-5">
								<p className="card-text">Add accepted insurance</p>
								<p className="card-text pb-5">
									Track each insurance repayment displaying specific and total
									Balances
								</p>
							</div>
						</div>
					</div>
					<div className="col-md-3 bg-transparent my-1">
						<div className="card rounded">
							<div className="card-header">
								<p className="h5 card-title">Services</p>
							</div>
							<img
								className="card-img-top"
								src={service}
								alt="health-management-tech-system-service"
							/>{" "}
							<div className="mx-1 py-5">
								<p className="card-text pb-5">
									Add services provided by your facility and cost eg
									consultation, paedetrician
								</p>
								<p className="card-text pb-4"></p>
							</div>
						</div>
					</div>
					<div className="col-md-3 bg-transparent my-1">
						<div className="card rounded">
							<div className="card-header">
								<p className="h5 card-title">Inventory</p>
							</div>
							<img
								className="card-img-top"
								src={inventory}
								alt="health-management-tech-system-inventory"
							/>
							<div className="mx-1 py-4">
								<p className="card-text">
									Don't Loose materials by using inventory manager
								</p>
								<p className="card-text">
									Be able to view inventory when they are received and to whom
									they are issued and when
								</p>
								<p className="card-text pb-4">
									Add inventory categories for easier management
								</p>
							</div>
						</div>
					</div>
					<div className="col-md-3 bg-transparent my-1">
						<div className="card rounded">
							<div className="card-header">
								<p className="h5 card-title">Expenses</p>
							</div>
							<img
								className="card-img-top"
								src={expenses}
								alt="health-management-tech-system-expenses"
							/>
							<div className="mx-1 py-4">
								<p className="card-text">
									Manage expenses like a proffessional
								</p>
								<p className="card-text">
									View when and who incurred a specific expense and total
									summary by month or year
								</p>
								<p className="card-text">
									Add expense categories to see which expenses are highly
									incurred
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Home;
